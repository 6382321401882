.sun-container {
	width: 100%;
	margin-top: 14px;
	text-align: center;
	font-weight: 200;
	border-top: 1px solid;
}

.sun-container > table {
	width: 100%;
	font-size: 17px;
	margin-top: 10px;
	text-transform: lowercase;
}

.sun-icons {
	font-size: 32px;
}

@media (min-width:641px) {
	.sun-container {
		border-top: none;
		margin-top: 29px;
	}

	.sun-container > table {
		font-size: 22px;
	}

	.sun-icons {
		font-size: 34px;
	}
}