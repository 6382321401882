body {
	background-image: linear-gradient(to bottom right, #817b90, #9198e5);
	background-attachment: fixed;
	box-sizing: border-box;
	color: #efefef;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.dark {
  background-image: linear-gradient(to bottom right, #5a595a, #07080c);
  height: 100vh;
}

.error-container {
	margin: 5rem auto;
	text-align: center;
	animation: fadeIn 1s ease-out;
}

.error-container * {
	font-weight: 100;
}

.desktop-container {
	max-width: 500px;
	margin: 0 auto;
	animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@media (min-width:641px) {
	.left, .right {
		width: 35vw;
		margin: 0 auto;
	}
}