.loader-container {
	opacity: 1;
	-webkit-animation: fadeInLoader 1s linear;
	-moz-animation: fadeInLoader 1s linear;
	-o-animation: fadeInLoader 1s linear;
	animation: fadeInLoader 1s linear;
}

.loader {
	margin-top: 150px;
  border: 16px solid #f5f5f5;
  border-top: 16px solid #a9a9a9;
  border-radius: 50%;
  width: 120px;
  height: 120px;
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	-o-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@keyframes fadeInLoader {
	from {
		opacity: 0;
	}

	to {
		opacity: .8;
	}
}

@keyframes spin {
  0% { 
		transform: rotate(0deg);
	}
  100% { 
		transform: rotate(360deg);
	}
}