.search-container {
	top: 0;
  width: 100vw;
  padding: 25px 0;
	display: flex;
	justify-content: center;
}

.search {
	color: #807777;
	text-align: center;
	background-color: whitesmoke;
	border: none;
	padding: 5px;
	box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.3);
	text-indent: 7px;
	font-size: 16px;
	-webkit-appearance: none;
	border-radius: 0;
}

.search:focus {
	outline: none;
}

.search::placeholder {
	font-style: italic;
	color: #ccc;
}

.search-btn {
	border: none;
	padding: 0 .7rem;
	color: #807777;
	background-color: #ddd;
}

@media (min-width:641px) {
	.search {
		padding: 10px;
		font-size: 26px; 
	}
}