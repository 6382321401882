.hourly-container {
	overflow-y: auto;
	bottom: 0;
	padding-top: 10px;
	margin-top: 15px;
	border-top: 1px solid #efefef;
}

.hourly-table {
	width: 100%;
	text-align: center;
}

.hour > td {
	width: 33.33%;
}

.hour {
	font-size: 20px;
	font-weight: 200;
}

@media (min-width:641px) {
	.hourly-container {
		height: auto;
		border: none;
		margin-top: 16px;
		padding: 0;
	}
	
	.hourly-table {
		width: 100%;
	}

	.hour > td {
		padding: 4px 0;
	}
}

@media (min-width:641px) {
	.hour {
		font-size: 28px;
	}
}